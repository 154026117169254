import React from "react";
import { concatClassNames as cn } from "@system42/core";
import { Headline1Sans, Overline } from "../../system42";
import * as styles from "./styles.module.css";

export function HeaderTitle(props) {
  const {
    className,
    children,
    overline,
    subtitle,
    subtitleWidth,
    titleWidth,
    ...containerProps
  } = props;

  return (
    <header className={cn(className, styles.headerTitle)} {...containerProps}>
      {overline && <Overline className={styles.overline}>{overline}</Overline>}
      <Headline1Sans className={styles.title} style={{ maxWidth: titleWidth }}>
        {children}
      </Headline1Sans>
      {subtitle && (
        <p className={styles.subtitle} style={{ maxWidth: subtitleWidth }}>
          {subtitle}
        </p>
      )}
    </header>
  );
}
