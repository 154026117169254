import React from "react";
import { concatClassNames as cn } from "@system42/core";

import Layout from "../../layouts/layout";

import { HeaderTitle } from "../../components/HeaderTitle";
import { HtmlContent } from "../../components/HtmlContent";
import { WavySeparator } from "../../components/WavySeparator";

import * as helperStyles from "../helpers.module.css";

const Sales = () => (
  <Layout title={"Sales Development Representative"}>
    <article>
      <HeaderTitle titleWidth={"13em"}>
        Sales Development Representative
      </HeaderTitle>

      <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

      <div className={helperStyles.container}>
        <HtmlContent
          className={cn(helperStyles.spacingMd, helperStyles.widthHtmlContent)}
        >
          <h2 id="company">Company</h2>
          <p>
            Userbrain is an online user testing tool, backed by thousands of
            testers, that will let you know what’s working for your product —
            and what’s not. With fast, simple, and affordable user tests, we
            empower designers, UX experts, founders, and marketers to build
            products people love to use.
          </p>
          <p>
            Our customer list includes big brands like: Spotify, Virgin, Audi
            and Tesla to name just a few.{" "}
          </p>
          <p>
            We are seeking a part-time dedicated Sales Development
            Representative (SDR) to develop and build relationships with
            prospects and grow our pipeline.
          </p>

          <h2 id="job">Job</h2>
          <ul>
            <li>Salary negotiable</li>
            <li>Part-time</li>
            <li>100% remote</li>
          </ul>

          <h2 id="-responsibilities-">
            <strong>Responsibilities</strong>
          </h2>
          <ul>
            <li>Making outbound / business-to-business calls</li>
            <li>
              Evangelizing and educating potential customers of the value of
              Userbrain
            </li>
            <li>Follow-up on inbound leads</li>
            <li>
              Product demonstrations via Zoom or other remote conferencing tools
            </li>
            <li>Qualifying and initiating relationships with new leads</li>
            <li>Identify new business opportunities</li>
            <li>Achieve sales targets as set by Sales Management Team</li>
            <li>
              Collaborate on a daily basis with your colleagues and update them
              on any developments
            </li>
          </ul>
          <h2 id="-requirements-">
            <strong>Requirements</strong>
          </h2>
          <ul>
            <li>12-18 months experience in B2B internal sales</li>
            <li>
              Confidence to overcome objections and convert interest into
              qualified leads
            </li>
            <li>High level of self-motivation</li>
            <li>An user experience interest or background</li>
            <li>Comfortable working remotely from your own initiative</li>
            <li>Evidence of working towards objectives and achieving goals</li>
            <li>
              Confident telephone manner with excellent communication skills
              (verbal and written)
            </li>
            <li>
              Excellent listener – listening to the prospect so they can respond
              appropriately
            </li>
            <li>
              We are looking for someone who can work to the Mountain/Pacific
              Time Zone.
            </li>
          </ul>
          <h2 id="-desirable-">
            <strong>Desirable</strong>
          </h2>
          <ul>
            <li>Experience selling SaaS solutions</li>
            <li>
              Experience with products related to User Experience or User
              Testing
            </li>
          </ul>
          <h2 id="perks">Perks</h2>
          <ul>
            <li>
              Freedom to live wherever you like so long as you have reliable,
              fast internet and a permanent residence with the ability to work
              predominantly PST hours.
            </li>
            <li>Largely PST business hours but you manage your day.</li>
            <li>
              1 x per year, you will enjoy an all-expenses-paid team trip to an
              exciting location in Europe (last trip has been a 14 day
              all-expenses-paid journey to Lisbon)
            </li>
            <li>Significant professional development opportunities</li>
            <li>
              Opportunity to go to conferences and other networking events
            </li>
          </ul>
          <p>
            <em>
              If this sounds like the challenge for you, then please apply
              today!
            </em>
          </p>

          <p style={{ marginTop: "1.5rem" }}>
            <a
              className="btn btn-default"
              href="https://www.surveymonkey.com/r/BH9MT2Z"
            >
              Apply now
            </a>
          </p>
        </HtmlContent>
      </div>
    </article>
  </Layout>
);

export default Sales;
