import React from "react";
import { concatClassNames as cn } from "@system42/core";
import * as styles from "./styles.module.css";

export function WavySeparator(props) {
  const { className, ...containerProps } = props;

  return (
    <hr className={cn(className, styles.wavySeparator)} {...containerProps} />
  );
}
