import React from "react";
import { concatClassNames as cn } from "@system42/core";
// import { } from '../../system42'
import * as styles from "./styles.module.css";

export function HtmlContent(props) {
  const { className, children, ...containerProps } = props;

  return (
    <div className={cn(className, styles.htmlContent)} {...containerProps}>
      {children}
    </div>
  );
}
